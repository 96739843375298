@font-face {
font-family: '__bodyFont_d37cc8';
src: url(/_next/static/media/cc2c3b4a718e95f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__bodyFont_d37cc8';
src: url(/_next/static/media/fb0ccca547491b59-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: '__bodyFont_d37cc8';
src: url(/_next/static/media/10278b9b4d460d3a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__bodyFont_Fallback_d37cc8';src: local("Arial");ascent-override: 100.88%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 97.84%
}.__className_d37cc8 {font-family: '__bodyFont_d37cc8', '__bodyFont_Fallback_d37cc8'
}.__variable_d37cc8 {--font-body: '__bodyFont_d37cc8', '__bodyFont_Fallback_d37cc8'
}

@font-face {
font-family: '__headingFont_f627cb';
src: url(/_next/static/media/0f939b1a5e4bf394-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__headingFont_f627cb';
src: url(/_next/static/media/6cf20d688888cba1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: bold;
}

@font-face {
font-family: '__headingFont_f627cb';
src: url(/_next/static/media/6a722094612266f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: bold;
}

@font-face {
font-family: '__headingFont_f627cb';
src: url(/_next/static/media/64d9b3d4ae7bd566-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: bold;
}@font-face {font-family: '__headingFont_Fallback_f627cb';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_f627cb {font-family: '__headingFont_f627cb', '__headingFont_Fallback_f627cb'
}.__variable_f627cb {--font-heading: '__headingFont_f627cb', '__headingFont_Fallback_f627cb'
}

